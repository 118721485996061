<template>
    <b-sidebar
      id="discount-item-sidebar"
      :visible="isDiscountItemSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData(itemData)"
    class="text-black"
      @change="(val) => $emit('update:is-discount-item-sidebar-active', val)"
    >
    <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                  Discount {{  Data.item_name }}
                </h5>
        
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
        </div>
        <div
              class="p-2 form-boder-input"
             
            
        >
            <b-row class="text-black">
                <b-col cols="3"  md="3">
                    <b-form-group
                            label="ราคา"
                            label-for="price"
                            rule="required"
                        >
            
                        <b-form-input v-model="Data.price" @input="changePrice"  />
                    </b-form-group>
                </b-col>
                <b-col cols="3"  md="3">
                    <b-form-group
                            label="จำนวน"
                            label-for="qty"
                            rule="required"
                        >
            
                        <b-form-input v-model="Data.qty" readonly />
                    </b-form-group>
                </b-col>
                <b-col cols="3"  md="3">
                    <b-form-group
                            label="ส่วนลด(%)"
                            label-for="discount"
                            rule="required"
                        >
            
                        <b-form-input v-model="Data.discount" @input="changeDiscount"  />
                    </b-form-group>
                </b-col>
                <b-col cols="3"  md="3">
                    <b-form-group
                            label="ราคารวม"
                            label-for="total"
                            rule="required"
                        >
            
                        <b-form-input v-model="Data.total" @input="changeTotal"   />
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex mt-2 flex-row-reverse">
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Close
              </b-button>
                <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                @click="onSubmit"
                
              >
                Save
              </b-button>
              
            </div>
        </div>
    </template>
    </b-sidebar>
</template>
<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable,BPagination,
BCol,BRow
} from 'bootstrap-vue'
import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { getComma } from '@core/utils/filter'
import receiptStoreModule from '../receiptStoreModule'
import { BFormSpinbutton } from 'bootstrap-vue'
export default {
    components: {
        BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable,
        BCol,BRow,
        vSelect,
        BFormSpinbutton,
        BPagination
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isDiscountItemSidebarActive',
      event: 'update:is-discount-item-sidebar-active',
    },
    props: {
        isDiscountItemSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      itemData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
      
        getComma
      }
    },
    setup(props, { emit }) {
        const Data = ref({});
        const getData = async (item)=>{
            Data.value = item;
        }
        const changePrice = async()=>{
          props.itemData.price = Data.value.price;
            let price = props.itemData.price;
            let qty = props.itemData.qty;
            let discount = props.itemData.discount;
            let total = price*qty;
            let discount_value = (price*qty)*(discount/100);
            Data.value.total = total-discount_value;
            const itm = {
              idIndex: props.itemData.idIndex,
              price : price,
              discount:discount,
              total:total
            }
            //await store.dispatch();
           

        }
        const changeDiscount = async()=>{
            let price = props.itemData.price;
            let qty = props.itemData.qty;
            let discount = props.itemData.discount;
            let total = price*qty;
            let discount_value = (price*qty)*(discount/100);
            Data.value.total = total-discount_value;
           
          
            //await store.dispatch();



        }
        const changeTotal = async()=>{
            let price = props.itemData.price;
            let qty = props.itemData.qty;
            let total_o =  price*qty;
            let total =  Data.value.total;
            let discount_value = total_o-total;
            let discount = (discount_value*100)/total_o;
            Data.value.discount = discount;
          
            
            //await store.dispatch();


        }
        const onSubmit = async()=>{
         
            let price = props.itemData.price;
            let qty = props.itemData.qty;
            let discount = props.itemData.discount;
            let total = price*qty;
            let discount_value = (price*qty)*(discount/100);
            Data.value.total = total-discount_value;
           

          const itm = {
              idIndex: props.itemData.idIndex?props.itemData.idIndex:props.itemData.id,
              price : price,
              discount:discount,
              total:Data.value.total
            }
            console.log('chg-itm',itm);
          const response = await store.dispatch('app-receipt/ChangeItemPrice',itm);
            emit('update:is-discount-item-sidebar-active', false)
            emit('refetch-data')
            
        }
        return {

            getData,
            changeDiscount,
            Data,
            changeTotal,
            onSubmit,
            changePrice
        }
    }
}
</script>