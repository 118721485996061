<template>
    <b-modal
      id="voucher-form-sidebar"
      :visible="isVoucherUsedSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      hide-footer
      
      @shown="getData(itemData,vouchers)"
    
      @change="(val) => $emit('update:is-voucher-used-sidebar-active', val)"
    >
        <template #modal-title>
            Voucher Used
        </template>
        <template #default>
            <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
          
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
            <validation-provider
              #default="validationContext"
              name="category_id"
              rules="required|integer"
            >
              <b-form-group
                label="ประเภทบัตรกำนัน"
                label-for="category_id"
              >
              <b-form-select
                v-model="Data.category_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                text-field="category_name"
                value-field="category_id"
                 :options="vouchers"
                 @input="ChangeCategory($event)"
                
               
              />
               
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="credit"
              rules="required|integer"
             
            >
              <b-form-group
                label="Credit"
                label-for="credit"
              >
                <b-form-input
                  id="credit"
                  v-model="Data.credit"
                  autofocus
                  :state="getValidationState(validationContext)"
                  @input="checkCredit"
                 
                  trim
                  placeholder="Credit"
                />
  
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
             
            </div>
            </validation-provider>
            </b-form>
          </validation-observer>
          
         
        </template>
    </b-modal>
</template>
<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'

  import {
    BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable, BModal,BFormSelect
  } from 'bootstrap-vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { ref,onUnmounted } from '@vue/composition-api'
  import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

  import formValidation from '@core/comp-functions/forms/form-validation'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import countries from '@/@fake-db/data/other/countries'
  import store from '@/store'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { useToast } from 'vue-toastification/composition'
import useVoucherUsed from './useVoucherUsed'
  import { getComma } from '@core/utils/filter'


  import voucherStoreModule from '../../voucher/voucherStoreModule'
  import { BFormSpinbutton } from 'bootstrap-vue'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
      BFormSelect,
      BModal,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BTable,
      BFormSpinbutton
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isVoucherUsedSidebarActive',
      event: 'update:is-voucher-used-sidebar-active',
    },
    props: {
      isVoucherUsedSidebarActive: {
        type: Boolean,
        required: true,
      },
      vouchers:{
        type: Array,
        required: true
      },
      itemData: {
        type: Object,
        required: false,
      },
      
    },
    data() {
      return {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
        getComma
      }
    },
    setup(props, { emit }) {
      const toast = useToast()
     
      const { t } = useI18nUtils()

      const VOUCHER_STORE_MODULE_NAME = 'app-voucher';
      if (!store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.registerModule(VOUCHER_STORE_MODULE_NAME, voucherStoreModule)
      onUnmounted(() => {
          if (store.hasModule(VOUCHER_STORE_MODULE_NAME)) store.unregisterModule(VOUCHER_STORE_MODULE_NAME)
      });    
      const {
        onSubmit,
        category_Data,
        Data,
        ChangeCategory,
        tableColumns,
        items,
        refvoucherListTable,
        fetchData,
        getData,
        checkCredit,
        max
      } = useVoucherUsed(emit,props);
      
      
  
      const {
        refFormObserver,
        getValidationState,
        resetForm,
      } = formValidation()
  
      return {
        t,
        onSubmit,
        category_Data,
        Data,
        ChangeCategory,
        tableColumns,
        items,
        refFormObserver,
        getValidationState,
        resetForm,
        refvoucherListTable,
        fetchData,
        getData,
        checkCredit,
        max
        
        
      }
    },
  }

</script>