import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useCourseNum(emit) {
    const t = useI18nUtils;
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const searchQuery = ref('');
    const blankData = {
      
        category_id:''
     
    }
    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    const cats = ref([]);
    const courses = ref([]);
    const refcourseListTable = ref();
    const items = ref([]);
    const tableColumns = [
      
      { key: 'no', label: '#', sortable: false },
      { key: 'title', label: t('list_service'), sortable: false },
     
      
      { key: 'qty', label: 'จำนวน', sortable: false },
      { key: 'price', label: 'ราคา/หน่วย', sortable: false },
    


  ];
    const getData = ()=>{

    }
    const ChangeCategory = (event)=>{
        // refvoucherListTable.value.refresh()
        courses.value  = [];
        
        getCourses();
       
        
   
    }
    const getCoursesCategory = async ()=>{
        const categories = await store.dispatch('app-course/fetchCategoryData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,*/
            is_active:1,
          //  branch_id:branch_id,
         
        });
        if(categories.data.success){
            cats.value = categories.data.data;

        }
        console.log('categories',cats.value);
        
    }
    getCoursesCategory();
    const getCourses = async ()=>{
      
        //fetchCourseData
       const course = await store.dispatch('app-course/fetchData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            
            type_id:selected_type.value,*/
            keywords: searchQuery.value,
            type_id:3,
            order_by:'courses.title  ASC',
            is_active:1,
            //branch_id:branch_id,
            
           });
           if(course.data.success){
                courses.value = course.data.data;
                if(courses.value.length > 0){
                    courses.value.map((itm)=>{
                      itm.qty = 0;
                      return itm;
      
                    });
      
                  }

            }
           console.log('course',course.data);
    }
   
    const onSubmit = async () => {
       
        let itms = courses.value.map((value)=>{
           
              let itm = {
                  id:value.id,
                  types:'course_num',
                  item_name: value.title,
                  description:'',
                  qty:value.qty,
                  price:value.price,
                  discount:0,
                  expire_day:value.expire_day,
                  total:value.price*value.qty
          
              }
              return itm;
  
            
  
        });
        //console.log('items',itms);
  
     
        emit('refetch-data', itms)
        emit('update:is-course-num-sidebar-active', false)
        
      }

    return {
        getData,
        cats,
        courses,
        tableColumns,
        refcourseListTable,
        items,
        Data,
        ChangeCategory,
        searchQuery,
        getCourses,
        onSubmit
    }
}