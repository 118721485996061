<template>
    <b-sidebar
      id="check-list-formr"
      :visible="isCheckListFormSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getData"
    class="text-black"
      @change="(val) => $emit('update:is-check-list-form-sidebar-active', val)"
    >
    <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
                 รายการตรวจ
                </h5>
        
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
        </div>
        <div
              class="p-2 form-boder-input"
             
            
        >
        <b-row class="text-black">
            
            <b-col cols="6"  md="6">
                <b-form-group
                    label="หมวดหมู่"
                    label-for="category"
                    >
                    <v-select
                        :options="category_Data"
                        v-model="category_id"
                        :get-option-label="(option) => option.title"
                        :reduce="val => val.id"
                        >
                        <template #option="{ title }">
                        {{ title }}
                        <br />
                        </template>
                    </v-select>
                </b-form-group>
            </b-col>
            
            <b-col cols="6"  md="6">
                <b-form-group
                label="ค้นหา"
                label-for="seach"
                >
                  <b-form-input v-model="searchQuery" />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <h3 class="text-black">รายการตรวจ</h3>
                <b-table
                        ref="refListTable"
                        :items="fetchData"
                        responsive
                        :fields="tableColumns"
                    
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="No matching records found"
                        :sort-desc.sync="isSortDirDesc"
                        class="mt-2 position-relative"
                >
                <template #cell(title)="data">
                 {{  data.item.title }}<br />
               
                    
                </template>
                <template #cell(sell_price)="data">
                    {{ getComma(data.item.price) }}
                </template>
                <template #cell(add)="data">
                    
                        <b-button
                       
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        class="btn-icon"
                        @click="addProduct(data.item)"
                        >
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                        
                    
                  
                  
                </template>
                </b-table>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totals"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                        </template>
                        <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
            </b-col>
            <b-col md="6">
                <h3 class="text-black">รายการที่เลือก</h3>
                <b-table
                        ref="refListSelectedTable"
                        :items="products_selected"
                        responsive
                        :fields="tableSelectedColumns"
                    
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="No matching records found"
                        :sort-desc.sync="isSortDirDesc"
                        class="mt-2 position-relative"
                >
                <template #cell(price)="data">
                    {{ getComma(data.item.price) }}
                </template>
                <template #cell(qty)="data">
                      <b-form-spinbutton
                        id="sb-inline"
                        v-model="data.item.qty"
                        @input="changeQty(data.item)"
                        min="1"
                        inline
                      />
                </template>
                <template #cell(remove)="data">
                    
                    <b-button
                    
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
                    class="btn-icon"
                    @click="removeProduct(data.item.index)"
                    >
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                    
                
              
              
            </template>
                </b-table>
            </b-col>
        </b-row>
        
        
       
        
        <div class="d-flex mt-2 flex-row-reverse">
              
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Close
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                v-if="products_selected.length > 0"
                @click="onSubmit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                disabled
                v-else
              >
                Add
              </b-button>
            </div>
        </div>
    </template>
    </b-sidebar>
</template>
<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable,BPagination,
BCol,BRow
} from 'bootstrap-vue'

import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { getComma } from '@core/utils/filter'
import receiptStoreModule from '../receiptStoreModule'
import { BFormSpinbutton } from 'bootstrap-vue'
import medicineStoreModule from '@/views/medicine/medicineStoreModule'
import useCheckListForm from './useCheckListForm'
import checklistsStoreModule from '@/views/checklists/checklistsStoreModule'
import categoryStoreModule from '@/views/checklists/categoryStoreModule'
export default {
    components: {
        BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable,
        BCol,BRow,
        vSelect,
        BFormSpinbutton,
        BPagination
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isCheckListFormSidebarActive',
      event: 'update:is-check-list-form-sidebar-active',
    },
    props: {
      isCheckListFormSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      userData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
      
        getComma
      }
    },
    setup(props, { emit }) {
        const toast = useToast()
        const MEDICINE_STORE_MODULE_NAME = 'app-medicine';
        if (!store.hasModule(MEDICINE_STORE_MODULE_NAME)) store.registerModule(MEDICINE_STORE_MODULE_NAME, medicineStoreModule)
        onUnmounted(() => {
            if (store.hasModule(MEDICINE_STORE_MODULE_NAME)) store.unregisterModule(MEDICINE_STORE_MODULE_NAME)
        });
        const CHECKLIST_STORE_MODULE_NAME = 'app-checklists';
            if (!store.hasModule(CHECKLIST_STORE_MODULE_NAME)) store.registerModule(CHECKLIST_STORE_MODULE_NAME, checklistsStoreModule)
            onUnmounted(() => {
                if (store.hasModule(CHECKLIST_STORE_MODULE_NAME)) store.unregisterModule(CHECKLIST_STORE_MODULE_NAME)
            });
            const CATEGORY_STORE_MODULE_NAME = 'app-checklists-category';
        if (!store.hasModule(CATEGORY_STORE_MODULE_NAME)) store.registerModule(CATEGORY_STORE_MODULE_NAME, categoryStoreModule)
        onUnmounted(() => {
            if (store.hasModule(CATEGORY_STORE_MODULE_NAME)) store.unregisterModule(CATEGORY_STORE_MODULE_NAME)
        });
       
        const {
            getData,
            onSubmit,
            group_Data,
            category_Data,
            type_Data,
            group_id,
            category_id,
            type_id,
            perPage,
            totals,
            currentPage,
            perPageOptions,
            searchQuery,
            refListTable,
            tableColumns,
            fetchData,
            sortBy,
            isSortDirDesc,
            refListSelectedTable,
            products_selected,
            tableSelectedColumns,
            addProduct,
            removeProduct,
            changeQty

        } = useCheckListForm(emit);
        return{
            getData,
            onSubmit,
            group_Data,
            category_Data,
            type_Data,
           
            group_id,
            category_id,
            type_id,
            perPage,
            totals,
            currentPage,
            perPageOptions,
            searchQuery,
            refListTable,
            tableColumns,
            fetchData,
            sortBy,
            isSortDirDesc,
            refListSelectedTable,
            products_selected,
            tableSelectedColumns,
            addProduct,
            removeProduct,
            changeQty

        }
    }
}
</script>
