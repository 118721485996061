import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useProductAdd(emit) {
    const group_Data  = ref([]);
    const category_Data = ref([]);
    const type_Data = ref([]);
    const blankData = {
        group_id:'',
        caetory_id:'',
        type_id:''

    }
    const group_id = ref();
    const category_id = ref();
    const type_id = ref();
    const perPage = ref(10)
    const totals = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const refListTable = ref(null);
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const refListSelectedTable = ref();
    const products_selected = ref([]);
    const tableColumns = [
      
        { key: 'title', label: 'Detail', sortable: false },
        { key: 'category_name', label:'Category', sortable:false},
        { key: 'sell_price', label:'sell_price', sortable:false},
        { key: 'add', label: '', sortable: false },


    ]
    const tableSelectedColumns = [
      
      { key: 'item_name', label: 'รายการ', sortable: false },
      { key: 'qty', label:'จำนวน', sortable:false},
      { key: 'price', label:'ราคา/หน่วย', sortable:false},
      { key: 'remove', label: '', sortable: false },


  ]
  const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    //const Data = ref(JSON.parse(JSON.stringify(blankData)))
    
    const getData = async ()=>{
        group_id.value=''
        category_id.value=''
        type_id.value=''
        products_selected.value = []
        fetchGroupData();
        fetchCategoryData();

    }
    const fetchGroupData  = async () => { 
        group_Data.value  = [];
        await store.dispatch('app-medicine/fetchGroupData',{
          is_active:1,
        })
        .then(response => {
        group_Data.value  = response.data.data
        })
        .catch((error) => {
        console.log('error',error);
        })
    }
    const refetchData = () => {
        refListTable.value.refresh()
    }
    watch([currentPage, perPage,group_id,category_id,type_id,searchQuery], () => {
        refetchData()
    })
    const fetchCategoryData  = async () => { 
        category_Data.value  = [];
        await store.dispatch('app-medicine/fetchCategoryData',{
            is_active:1,
          
            //branch_id:branch_id,
          })
          .then(response => {
    
          response.data.data.forEach((data) => {
            if(data.group_id == 1){category_Data.value.push(data)}
            else if(data.group_id == 2){type_Data.value.push(data)}
          });
          
          })
          .catch((error) => {
          console.log('error',error);
          })
      }
      const fetchData = (ctx, callback) => {
        const offset = perPage.value*(currentPage.value-1);
       
        store
          .dispatch('app-medicine/fetchData', {
            limit: perPage.value,
            offset:offset,
            keywords: searchQuery.value,
            branch_id:branch_id,
            category_id:category_id.value,
            group_id:group_id.value,
            type_id:type_id.value,
            is_active:1
          //  keywords: searchQuery.value,
           // category_id:catFilter.value
            
              /* page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,*/
          })
          .then(response => {
           
            const  items  = response.data.data

            const items_a =  items.map((value)=>{
                value.balance = value.stock_in-value.stock_out;
                return value;
            });
            
            console.log('products',items_a);
            const total = response.data.total?response.data.total:items.length;
            callback(items_a)
            totals.value = total
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching Category' list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    }
    const changeQty = async (item)=>{
      const index = products_selected.value.findIndex(s => s.id === item.id)
      if(index > -1){
        products_selected.value[index].total =  products_selected.value[index].price* products_selected.value[index].qty ;
      }
      
    }
    const addProduct = async (item)=>{
    const index = products_selected.value.findIndex(s => s.id === item.id)
    if(index > -1){
      products_selected.value[index].qty += 1
      products_selected.value[index].total =  products_selected.value[index].price* products_selected.value[index].qty ;

    }else{
      let itm = {
          id:item.id,
          types:'medicines',
          item_name:item.title,
          description:'',
          qty:1,
          price:item.sell_price,
          discount:0,
          voucher:0,
          vcat_id:0,
          total:item.sell_price*1

      }
      products_selected.value.push(itm);
    }
     
    
    

    }
    const removeProduct = async (index)=>{
      products_selected.value.splice(index, 1);

    }
    const onSubmit = async () =>{
      emit('refetch-data', products_selected.value)
      emit('update:is-product-add-sidebar-active', false)

    }
    return {

        getData,
        onSubmit,
        group_Data,
        category_Data,
        type_Data,
        group_id,
        category_id,
        type_id,
        perPage,
        totals,
        currentPage,
        perPageOptions,
        searchQuery,
        refListTable,
        tableColumns,
        fetchData,
        sortBy,
        isSortDirDesc,
        refListSelectedTable,
        products_selected,
        tableSelectedColumns,
        addProduct,
        removeProduct,
        changeQty
    }
}