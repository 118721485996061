import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { getCommaDecimal } from '@core/utils/filter'
export default function useGatewayForm(emit){
    const blankData = {
        price:'',
        gateway_id:[],
        payments:[],

    }
    const gateways = ref([]);
    const balance = ref(0);
    const Data = ref(JSON.parse(JSON.stringify(blankData)))
    const getData = async (price)=>{
        Data.value.price = price;
        balance.value = price;
       
        let response = await store.dispatch('app-receipt/fetchGatewayData',{});
        if(response.data.success){
            gateways.value = response.data.data
        }
       // Data.value.gateway_id = gateways.value[0].id
        console.log('useGatewayForm', gateways.value);
        
    }
    const onSubmit = async ()=>{
        emit('refetch-data', Data.value)
        emit('update:is-gateway-form-active', false)

    }
    const resetData = async ()=>{
        Data.value = blankData;
        //fetchGatewayData

    }
    const updatePayment = async (gateway)=>{
        //let items = event
        let item = gateway;
        item.price = balance.value;
        balance.value = 0;
        Data.value.payments.push(item); 
       /* await Data.value.payments.map((value)=>{
            const index = Data.value.gateway_id.findIndex(p => p.id === value.id );
            if(index > -1){

            }else{
                let dat = Data.value.gateway_id[index]
                dat.price = Data.value.price
                Data.value.payments.push(dat); 
            }
            console.log('event-value',dat);


        })*/
        //const index = gateways.value.findIndex(p => p.id === v.id );
      
    }
    const updatePaymentPrice = async ()=>{
        let price =   Data.value.price;
        Data.value.payments =  await Data.value.payments.map((value)=>{
            console.log(balance.value, value.price);
            if(value.price*1 > balance.value){
                value.price = balance.value;
                price = 0;

            }else{
                price  -=  value.price

            }
          

            return value;
         
        });
        balance.value = price;
        console.log('balance', balance.value);

    }
    const removePayment = async (gateway)=>{
        balance.value += gateway.price
        const index =  Data.value.payments.findIndex(i => i.id === gateway.id)
        Data.value.payments.splice(index, 1)
        
    }
    return {
        getData,
        onSubmit,
        resetData,
        Data,
        gateways,
        getCommaDecimal,
        updatePayment,
        updatePaymentPrice,
        balance,
        removePayment
    }

}