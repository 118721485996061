<template>
    <b-sidebar
      id="course-set-add-sidebar"
      :visible="isCourseSetAddSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @shown="getCourses"
    
      @change="(val) => $emit('update:is-course-set-add-sidebar-active', val)"
    >
        <template #default="{ hide }">
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">
                    Course Set
                </h5>
        
                <feather-icon
                    class="ml-1 cursor-pointer"
                    icon="XIcon"
                    size="16"
                    @click="hide"
                />
        
          </div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              class="p-2"
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
             
              <div class="mt-2">
                <b-table
                ref="refcourseListTable"
                responsive
                    :fields="tableColumns"
                   
                    :items="courses"
                    show-empty
                    empty-text="No matching records found"
                   
                    class="position-relative"
                >
                <template #cell(qty)="data">
                      <b-form-spinbutton
                        id="sb-inline"
                        v-model="data.item.qty"
                        min="0"
                        inline
                      />
                </template>
                <template #cell(price)="data">
                    
                    {{  getComma(data.item.price) }}
                </template>
                </b-table>
              </div>
              <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Close
              </b-button>
            </div>
            </b-form>
          </validation-observer>
        </template>
    </b-sidebar>
    
</template>
<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import {
BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton,BTable
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref,onUnmounted } from '@vue/composition-api'
import { required, alphaNum, email, password,confirmed } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

import { getComma } from '@core/utils/filter'



import receiptStoreModule from '../receiptStoreModule'
import courseStoreModule from '@/views/course/courseStoreModule'
import { BFormSpinbutton } from 'bootstrap-vue'
import useCourseSetAdd from './useCourseSetAdd'
export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormCheckbox,
      BFormInvalidFeedback,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
      BTable,
      BFormSpinbutton
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isCourseSetAddSidebarActive',
      event: 'update:is-course-set-add-sidebar-active',
    },
    props: {
      isCourseSetAddSidebarActive: {
        type: Boolean,
        required: true,
      },
     
      userData: {
        type: Object,
        required: false,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
        countries,
        getComma
      }
    },
    setup(props, { emit }) {
        
        const toast = useToast()
        //const { t } = useI18nUtils()
        const COURSE_STORE_MODULE_NAME = 'app-course';
        if (!store.hasModule(COURSE_STORE_MODULE_NAME)) store.registerModule(COURSE_STORE_MODULE_NAME, courseStoreModule)
        onUnmounted(() => {
            if (store.hasModule(COURSE_STORE_MODULE_NAME)) store.unregisterModule(COURSE_STORE_MODULE_NAME)
        });    
        const {
            getData,
            cats,
            courses,
            tableColumns,
            refcourseListTable,
            items,
            Data,
            ChangeCategory,
            getCourses,
            onSubmit
        } = useCourseSetAdd(emit);
        return{
            getData,
            cats,
            courses,
            tableColumns,
            refcourseListTable,
            items,
            Data,
            ChangeCategory,
            getCourses,
            onSubmit
           
        }
    }
}
</script>
