<template>
    <div>
        <b-modal 
        :visible="isGatewayFormActive"
        hide-footer
        @change="(val) => $emit('update:is-gateway-form-active', val)"
        @shown="getData(price)"
        >
        <template #modal-title>
            {{  title  }} จำนวนยอดที่ต้องชำระ  {{  getCommaDecimal(Data.price) }} บาท
        </template>
        <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
        >
            <b-form
            class="p-2 form-boder-input"
                @submit.prevent="handleSubmit(onSubmit)"
            
            >

              <b-row  v-for="gateway in gateways" :key="'g-'+gateway.id" class="table border-bottom" v-if="Data.payments.findIndex(p => p.id === gateway.id ) < 0">
                    <b-col cols="10" align-self="center">
                        {{ gateway.title }}
                    </b-col>
                    <b-col cols="2" align-self="center">
                        <b-button
                      
                        
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                         variant="flat-success"
                         class="btn-icon"
                        @click="updatePayment(gateway)"
                        
                        >
                            <feather-icon icon="PlusCircleIcon" />
                        </b-button>
                    </b-col>

              </b-row>
              <b-row  v-for="(g,i) in  Data.payments"  class=" " :key ="'pg-'+i">
                <b-col cols="10" align-self="center">
                    <b-form-group
                  
                 
                            :label="'ยอดชำระ '+ g.title"
                            label-for="gateway_id"

                        >
                        <b-form-input
                                
                                v-model="g.price"
                                
                                @input="updatePaymentPrice(i)"
                                
                                placeholder="Price"
                            />
                

                        </b-form-group>
                </b-col>
                <b-col cols="2" align-self="center"> 
                        <b-button
                      
                        
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                         variant="flat-danger"
                         class="btn-icon"
                        @click="removePayment(g)"
                        
                        >
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                    </b-col>
              </b-row>
             
               
                <!--<validation-provider
                    #default="validationContext"
                    name="price"
                    rules="required"
                >
                    <b-form-group
                    label="Price"
                    label-for="price"
                    >
                    <b-form-input
                        id="price"
                        v-model="Data.price"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Price"
                    />
        
                    <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>-->
                <div class="d-flex mt-2">
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                     v-if="balance == 0 || title == 'วางมัดจำ'"
                    >
                    Submit
                    </b-button>
                    <b-button

                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                    disabled
                     v-else
                    >
                    Submit
                    </b-button>
                   
                </div>
            </b-form>
        </validation-observer>

        </b-modal>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton,BModal,
    BForm,BFormInvalidFeedback
} from 'bootstrap-vue'
import store from '@/store';
import { ref,onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import receiptStoreModule from '../receiptStoreModule';
import useGatewayForm from './useGatewayForm';

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password,confirmed } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import vSelect from 'vue-select'

import { integer } from 'vee-validate/dist/rules';

export default{
    model: {
      prop: 'isGatewayFormActive',
      event: 'update:is-gateway-form-active',
    },
    props: {
      isGatewayFormActive: {
            type: Boolean,
            required: true,
      },
      price:{
            type: Number,
            required:false
      },
      title:{
            type: String,
            required:false
      }
    },
    components:{
        BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,BFormDatepicker,BFormSpinbutton,BModal,BForm,BFormInvalidFeedback,
    ValidationProvider, ValidationObserver,
    vSelect
    },
    directives:{
      //  'b-modal': VBModal,
        Ripple

    },
    setup(props, { emit }){
        const RECEIPT_STORE_MODULE_NAME = 'app-receipt';
        if (!store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.registerModule(RECEIPT_STORE_MODULE_NAME, receiptStoreModule)
        onUnmounted(() => {
            if (store.hasModule(RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(RECEIPT_STORE_MODULE_NAME)
        });
        const {
            getData,
            onSubmit,
            Data,
            gateways,
            resetData,
            getCommaDecimal,
            updatePayment,
            updatePaymentPrice,
            balance,
            removePayment
        } = useGatewayForm(emit);
        const {
            refFormObserver,
            
      
            getValidationState,
            resetForm,
        } = formValidation(resetData)
        return {
            getData,
            onSubmit,
            Data,
            resetData,
            refFormObserver,
            getValidationState,
            resetForm,
            gateways,
            getCommaDecimal,
            updatePayment,
            updatePaymentPrice,
            balance,
            removePayment
        }

    }
}

</script>
