import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useVoucherUsed(emit,props) {
    const { t } = useI18nUtils();
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    
    const user_id = ref(router.currentRoute.params.user_id);
    const category_Data = ref([]);
    const items = ref([]);
    const max = ref(0);
    const vrac = ref([]);
   
    const blankData = {
      
        credit:'',
        category_id:''
   
    }
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
    const refvoucherListTable = ref();

    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    const tableColumns = [
      
      { key: 'no', label: '#', sortable: false },
      { key: 'title', label: t('list_service'), sortable: false },
      { key: 'credit', label:'Credit', sortable: false },
      
      { key: 'qty', label: 'จำนวน', sortable: false },
      { key: 'price', label: 'ราคา/หน่วย', sortable: false },
    


  ];
    const fetchCategoryData  = async () => { 
        category_Data.value  = [];
        await store.dispatch('app-voucher/fetchCategoryData',{
          branch_id:branch_id,
          is_active:1,
        })
        .then(response => {
          response.data.data.forEach((data) => {
            if(data.group_id == 1){category_Data.value.push(data)}
            else if(data.group_id == 2){type_Data.value.push(data)}
          });
        console.log('voucher fetchCategoryData value',category_Data.value);
    
        })
        .catch((error) => {
        console.log('error',error);
        })
    }
    
    fetchCategoryData();
    const onSubmit = async () => {
     
      
      let total = (props.itemData.price*props.itemData.qty)-props.itemData.discount;  
     
      props.itemData.vcat_id = Data.value.category_id;
      
      props.itemData.voucher = Data.value.credit;
      props.itemData.total =  (total-Data.value.credit);
      console.log('items',Data.value);
    
      emit('refetch-data', Data.value)
      emit('update:is-voucher-used-sidebar-active', false)
      
    }
    /*watch([Data.value.category_id], () => {
      ChangeCategory()
    })*/
    const ChangeCategory = (event)=>{
     
      const index = vrac.value.findIndex(s => s.category_id === event)
   //   props.itemData.vcat_id = event;
     let total = (items.value.price*items.value.qty)-items.value.discount;
     
      if(total*1 < vrac.value[index].balance*1){
        max.value = items.value.total
      }else{
        max.value = vrac.value[index].balance
      }
      checkCredit();
     // refvoucherListTable.value.refresh()
  
     

    }
    const getData = (item,vouchers)=>{ 
     
      
      vrac.value = vouchers;
      items.value = item;
      if(item.vcat_id){
        if(item.vcat_id == 0){
          Data.value.category_id = vouchers[0].category_id;
         
        }else{
          Data.value.category_id =item.vcat_id ;
        }
        Data.value.credit = item.voucher;
        let total = (item.price*item.qty)-item.discount;
       
        if(total*1 < vouchers[0].balance*1){
          max.value = total;
        }else{
          max.value = vouchers[0].balance
        }
        
      }else{
        Data.value.category_id = vouchers[0].category_id;
        Data.value.credit = item.balance;
        let total = (item.price*item.qty)-item.discount;
       
        if(total*1 < vouchers[0].balance*1){
          max.value = total;
        }else{
          max.value = vouchers[0].balance
        }
      }
      
     

  
  
 
     
    }
    const checkCredit = ()=>{
     // console.log('Input',Data.value.credit);
     // console.log('max',max.value);
   /*   let total = (props.itemData.price*item.qty)-props.itemData.discount;
     
      if(total*1 < vouchers[0].balance*1){
        max.value = total;
      }else{
        max.value = vouchers[0].balance
      }*/

      if(max.value < Data.value.credit){
         Data.value.credit = max;

      }
      

    }
    const fetchData = async (ctx, callback) => {
    
    }

    return {
       
        t,
        onSubmit,
        ChangeCategory,
        category_Data,
        Data,
        tableColumns,
        items,
        refvoucherListTable,
        fetchData,
        getData,
        checkCredit
    }
}